import React from 'react';
import Layout from '../../components/CleanLayout';
import Thankyou from '../../components/thankyou/Thankyou';
import AddEvent from '../../components/registration/Webinar5/AddEvent';

const METADATA = [
  {
    name: 'description',
    content:
      "Thank you for registering for Subtle Medical's webinar.",
  },
  {
    name: 'keywords',
    content: 'SubtlePET, SubtleMR, PET, MRI, radiology, AI, Subtle Medical, SubtleMR',
  },
];

/**
 * Thank you page with addToEvent calendar buttons
 */
const index = () => (
  <Layout pageTitle="Thank You for Registering" metadata={METADATA}>
    <Thankyou>
      <p className="thankyou--subtext">
        You'll receive a confirmation email soon. If you can't locate it, please
        make sure it wasn't caught in a filter.
      </p>
      <AddEvent eventId="Nu5705921" />
    </Thankyou>
  </Layout>
);

export default index;
