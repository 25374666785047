import React from 'react';

/**
 * Links generated on AddToEvent.com
 *
 */
const AddEvent = props => {
  const { eventId, text = 'Add event to calendar' } = props;

  return (
    <>
      <p
        style={{
          margin: '24px 0px 6px 0px',
          textAlign: 'center',
          fontSize: '17px',
          lineHeight: '150%',
          color: '#000',
          fontWeight: 'bold',
        }}
      >
        {text}
      </p>
      <p
      className="flex justify-center"
        style={{
          margin: '0px 0px 10px 0px',
          textAlign: 'center',
          fontSize: '0px',
        }}
      >
        <a
          className="inline"
          href={`https://www.addevent.com/event/${eventId}+apple`}
          title="Apple"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-apple-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          className="inline"
          href={`https://www.addevent.com/event/${eventId}+google`}
          title="Google"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-google-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href={`https://www.addevent.com/event/${eventId}+office365`}
          title="Office 365"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-office365-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href={`https://www.addevent.com/event/${eventId}+outlook`}
          title="Outlook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-outlook-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href={`https://www.addevent.com/event/${eventId}+outlookcom`}
          title="Outlook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-outlookcom-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href={`https://www.addevent.com/event/${eventId}+yahoo`}
          title="Yahoo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-yahoo-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
      </p>
    </>
  );
};

export default AddEvent;
